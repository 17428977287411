import React from 'react'

import styled from '@emotion/styled'

import Icon from '../common/Icon'

// name : 지역 이름 (필수)
// cnt : 매장 갯수 (필수)
// contents (필수)
// checked : 선택됐을 시 체크표시 여부 - boolean`

const MustgoBanner = ({ name, cnt, contents, checked, t }) => {
  return (
    <Container>
      {contents}
      <InfoBox>
        <SpaceBetween>
          <Name>{name}</Name>
          {checked && <Icon image="done" color="white" />}
        </SpaceBetween>
        <Cnt>
          {cnt.toLocaleString('ko-kr')} {t('product:store_count')}
        </Cnt>
      </InfoBox>
    </Container>
  )
}

export default MustgoBanner

const Container = styled.div`
  height: 140px;
  position: relative;
  cursor: pointer;
  color: white;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
  }
`

const InfoBox = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px 8px 0 8px;
`

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Name = styled.h4`
  font-size: 20px;
`

const Cnt = styled.p`
  font-size: 14px;
  font-weight: 300;
  word-break: keep-all;
`
