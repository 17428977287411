import React from 'react'

import styled from '@emotion/styled'
import { useTranslation } from 'next-i18next'

const TourBanner = ({ name, contents }) => {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <Container>
      {contents}
      <InfoBox>
        <MustEat>
          <Must>Must</Must>
          <Eat>eat</Eat>
        </MustEat>
        <Name lang={language}>{name}</Name>
      </InfoBox>
    </Container>
  )
}

export default TourBanner

const Container = styled.div`
  height: 124px;
  position: relative;
  cursor: pointer;
  color: white;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
  }
`

const InfoBox = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`

const MustEat = styled.ol`
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
`

const Must = styled.li`
  background-color: black;
  font-size: 12px;
  padding: 0 4px 4px 4px;
  margin-bottom: 1px;
`

const Eat = styled(Must)`
  background-color: #ff4a52;
`

const Name = styled.h3`
  text-align: center;
  font-size: ${(props) =>
    props.lang === 'en' || props.lang === 'es' ? 16 : 32}px;
  font-weight: 500;
  line-height: 1;
`
