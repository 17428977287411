import React, { useMemo } from 'react'

import { useSelector, useDispatch } from 'react-redux'
import { Cookies } from 'react-cookie'
import { message } from 'antd'

import { Favorite, FavoriteFill } from '../common/icon/Favorite'
import { postFrontUserBookmark } from 'api'
import { deleteWishlist, insertWishlist } from 'store/wishlist'
import Image from 'next/image'

const Bookmark = ({
  store_id,
  product_id,
  isKTC = false,
  absolute = false,
  styles = null, // 커스텀 스타일 넣으려면 absolute도 true로 해야
  onCount = null,
}) => {
  const dispatch = useDispatch()
  const wishlist = useSelector((state) => state.wishlist.wishlist)
  const token = useSelector((state) => state.auth.token)
  const cookies = new Cookies()

  const id = useMemo(() => {
    if (!product_id) return store_id
    else return product_id
  }, [])

  const isMarked = useMemo(() => {
    if (!wishlist) return false
    else return wishlist.includes(id)
  }, [wishlist])

  const toggleHandler = async (e) => {
    e.preventDefault()
    if (!token) return message.warning('Sigin is required')

    await postFrontUserBookmark({ token, store_id, product_id })
      .then((res) => {
        if (res === 401) {
          cookies.remove('token')
          return message.warning('Sigin is required')
        }

        if (isMarked) dispatch(deleteWishlist({ wishlist: id }))
        else dispatch(insertWishlist({ wishlist: id }))

        // KTC 모드일때 카운트 숫자 변경
        if (isKTC && onCount) {
          if (isMarked) onCount((prev) => prev - 1)
          else onCount((prev) => prev + 1)
        }
      })
      .catch(() => message.error('Please, Try again'))
  }

  const positionAbsolute = {
    position: 'absolute',
    top: 8,
    right: 8,
    ...styles,
  }

  return isMarked ? (
    <div onClick={toggleHandler}>
      {isKTC ? (
        <Image
          src="/ktc/heart-fill.png"
          alt=""
          width={50}
          height={50}
          style={absolute ? positionAbsolute : ''}
        />
      ) : (
        <FavoriteFill />
      )}
    </div>
  ) : (
    <div onClick={toggleHandler}>
      {isKTC ? (
        <Image
          src="/ktc/heart.png"
          alt=""
          width={50}
          height={50}
          style={absolute ? positionAbsolute : ''}
        />
      ) : (
        <Favorite />
      )}
    </div>
  )
}

export default Bookmark
