import React from 'react'
import Link from 'next/link'

import styled from '@emotion/styled'

const HealingBanner = ({ id, region, desc, name, price, contents, t }) => {
  return (
    <Container>
      <Link href={`/food/${id}`}>
        {contents}
        <InfoBox>
          <li>
            <Region>{region}</Region>
          </li>
          <li>
            <Desc>{desc}</Desc>
          </li>
          <li>
            <Name>{name}</Name>
          </li>
          <li>
            <Price>{price}</Price>
          </li>
        </InfoBox>
        <Button>{t('product:pay_reserve')}</Button>
      </Link>
    </Container>
  )
}

export default HealingBanner

const Container = styled.div`
  height: 200px;
  color: white;
  cursor: pointer;

  img {
    height: 100% !important;
    position: relative;
    z-index: -1;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`

const InfoBox = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  padding: 15px 10px 0 10px;

  li {
    margin-bottom: 4px;
  }
`

const Region = styled.p`
  font-size: 12px;
  font-weight: 100;
  line-height: 1.2;
`

const Desc = styled(Region)`
  word-break: keep-all;
  line-height: 1.2;
`

const Name = styled.h4`
  font-size: 16px;
  font-weight: 500;
  word-break: keep-all;
  line-height: 1.2;
`

const Price = styled.h4`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
`

const Button = styled.div`
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  background-color: rgba(255, 0, 0, 0.75);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  position: absolute;
  bottom: 0;
  left: 0;
`
