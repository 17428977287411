import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import styled from '@emotion/styled'

import Icon from './common/Icon'
import Image from 'next/image'

const Footer = ({ t }) => {
  const router = useRouter().route
  const [current, setCurrent] = useState(router)

  const RED =
    'invert(16%) sepia(89%) saturate(6054%) hue-rotate(358deg) brightness(97%) contrast(113%)'

  useEffect(() => {
    setCurrent(router)
  }, [router])

  const icons = [
    {
      label: t('home'),
      image: 'cottage_footer',
      link: '/',
    },
    {
      label: t('wish_list'),
      image: 'favorite_md',
      link: '/wishlist',
    },
    {
      label: t('city'),
      image: 'domain',
      link: '/select-city',
    },
    {
      label: t('order_history'),
      image: 'local_activity',
      link: '/order',
    },
    {
      label: t('mypage'),
      image: 'account_circle',
      link: '/mypage',
    },
  ]

  return (
    <Container>
      <IconUL>
        {icons.map((icon) => (
          <li key={icon.link}>
            <Icon
              label={icon.label}
              image={
                <Image
                  src={`/icon/${icon.image}.svg`}
                  alt=""
                  width={24}
                  height={24}
                  style={{
                    filter: icon.link === current ? RED : 'none',
                  }}
                />
              }
              link={icon.link}
            />
          </li>
        ))}
      </IconUL>
    </Container>
  )
}

export default Footer

const Container = styled.footer`
  max-width: 480px;
  width: 100%;
  height: 72px;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #e8e8e8;
  background-color: white;
  z-index: 1;

  display: flex;
  justify-content: space-around;
  align-items: center;
`

const IconUL = styled.ul`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: -10px;
`
