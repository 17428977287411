/* eslint-disable @next/next/no-img-element */
import React from 'react'

export const Favorite = () => {
  return (
    <img
      src="/icon/favorite.svg"
      alt=""
      width={24}
      height={24}
      style={{
        filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))',
        position: 'absolute',
        top: 8,
        right: 8,
      }}
    />
  )
}

export const FavoriteFill = () => {
  return (
    <img
      src="/icon/favorite_fill.svg"
      alt=""
      width={24}
      height={24}
      style={{
        filter: 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2))',
        position: 'absolute',
        top: 8,
        right: 8,
      }}
    />
  )
}
