export const theme = [
  {
    com_area_id: 234,
    musteat_id: 8849,
    ko: '/asset/theme/main/main_theme_banner_ko_11.jpg',
    en: '/data/theme/en/point_en.jpg',
    ja: '/data/theme/ja/point_jp.jpg',
    'zh-Hans': '/data/theme/zh-Hans/point_zh-hans.jpg',
    es: '/data/theme/en/point_en.jpg',
  },
  {
    com_area_id: 234,
    musteat_id: 8850,
    ko: '/asset/theme/main/main_theme_banner_ko_12.jpg',
    en: '/data/theme/en/bunwigi_en.jpg',
    ja: '/data/theme/ja/bunwigi_jp.jpg',
    'zh-Hans': '/data/theme/zh-Hans/bunwigi_zh-Hans.jpg',
    es: '/data/theme/en/bunwigi_en.jpg',
  },
  {
    com_area_id: 234,
    musteat_id: 8593,
    ko: '/asset/theme/main/main_theme_banner_ko_3.jpg',
    en: '/asset/theme/main/main_theme_banner_en_3.jpg',
    ja: '/asset/theme/main/main_theme_banner_ja_3.jpg',
    'zh-Hans': '/asset/theme/main/main_theme_banner_zh_3.jpg',
    es: '/asset/theme/main/main_theme_banner_en_3.jpg',
  },
  {
    com_area_id: 234,
    musteat_id: 8591,
    ko: '/asset/theme/main/main_theme_banner_ko_1.jpg',
    en: '/asset/theme/main/main_theme_banner_en_1.jpg',
    ja: '/asset/theme/main/main_theme_banner_ja_1.jpg',
    'zh-Hans': '/asset/theme/main/main_theme_banner_zh_1.jpg',
    es: '/asset/theme/main/main_theme_banner_en_1.jpg',
  },
  {
    com_area_id: 234,
    musteat_id: 8592,
    ko: '/asset/theme/main/main_theme_banner_ko_2.jpg',
    en: '/asset/theme/main/main_theme_banner_en_2.jpg',
    ja: '/asset/theme/main/main_theme_banner_ja_2.jpg',
    'zh-Hans': '/asset/theme/main/main_theme_banner_zh_2.jpg',
    es: '/asset/theme/main/main_theme_banner_en_2.jpg',
  },
  {
    com_area_id: 234,
    musteat_id: 8594,
    ko: '/asset/theme/main/main_theme_banner_ko_4.jpg',
    en: '/asset/theme/main/main_theme_banner_en_4.jpg',
    ja: '/asset/theme/main/main_theme_banner_ja_4.jpg',
    'zh-Hans': '/asset/theme/main/main_theme_banner_zh_4.jpg',
    es: '/asset/theme/main/main_theme_banner_en_4.jpg',
  },
  {
    com_area_id: 234,
    musteat_id: 8596,
    ko: '/asset/theme/main/main_theme_banner_ko_5.jpg',
    en: '/asset/theme/main/main_theme_banner_en_5.jpg',
    ja: '/asset/theme/main/main_theme_banner_ja_5.jpg',
    'zh-Hans': '/asset/theme/main/main_theme_banner_zh_5.jpg',
    es: '/asset/theme/main/main_theme_banner_en_5.jpg',
  },
  {
    com_area_id: 234,
    musteat_id: 8597,
    ko: '/asset/theme/main/main_theme_banner_ko_6.jpg',
    en: '/asset/theme/main/main_theme_banner_en_6.jpg',
    ja: '/asset/theme/main/main_theme_banner_ja_6.jpg',
    'zh-Hans': '/asset/theme/main/main_theme_banner_zh_6.jpg',
    es: '/asset/theme/main/main_theme_banner_en_6.jpg',
  },
  {
    com_area_id: 234,
    musteat_id: 8600,
    ko: '/asset/theme/main/main_theme_banner_ko_7.jpg',
    en: '/asset/theme/main/main_theme_banner_en_7.jpg',
    ja: '/asset/theme/main/main_theme_banner_ja_7.jpg',
    'zh-Hans': '/asset/theme/main/main_theme_banner_zh_7.jpg',
    es: '/asset/theme/main/main_theme_banner_en_7.jpg',
  },
  {
    com_area_id: 234,
    musteat_id: 8599,
    ko: '/asset/theme/main/main_theme_banner_ko_8.jpg',
    en: '/asset/theme/main/main_theme_banner_en_8.jpg',
    ja: '/asset/theme/main/main_theme_banner_ja_8.jpg',
    'zh-Hans': '/asset/theme/main/main_theme_banner_zh_8.jpg',
    es: '/asset/theme/main/main_theme_banner_en_8.jpg',
  },
  {
    com_area_id: 234,
    musteat_id: 8598,
    ko: '/asset/theme/main/main_theme_banner_ko_9.jpg',
    en: '/asset/theme/main/main_theme_banner_en_9.jpg',
    ja: '/asset/theme/main/main_theme_banner_ja_9.jpg',
    'zh-Hans': '/asset/theme/main/main_theme_banner_zh_9.jpg',
    es: '/asset/theme/main/main_theme_banner_en_9.jpg',
  },
  {
    com_area_id: 234,
    musteat_id: 8595,
    ko: '/asset/theme/main/main_theme_banner_ko_10.jpg',
    en: '/asset/theme/main/main_theme_banner_en_10.jpg',
    ja: '/asset/theme/main/main_theme_banner_ja_10.jpg',
    'zh-Hans': '/asset/theme/main/main_theme_banner_zh_10.jpg',
    es: '/asset/theme/main/main_theme_banner_en_10.jpg',
  },
]

export const flex = [
  {
    id: 10754,
    name: {
      ko: '파빌리온',
      en: '63 BUFFET PAVILION',
      ja: '63 BUFFET PAVILION',
      'zh-Hans': '63 BUFFET PAVILION',
      es: '63 BUFFET PAVILION',
    },
    desc: {
      ko: '국내 최대 규모 63뷔페',
      en: 'The largest buffet in the country',
      ja: '韓国最大規模のビュッフェ',
      'zh-Hans': '韩国最大规模自助餐厅',
      es: 'The largest buffet in the country',
    },
    image: '/data/store/store/461336_Tk9e90rNFb6HBQYRYdcPl0bjzQwR54hF.jpg',
    region: {
      ko: '서울 / 영등포',
      en: 'Seoul / Yeongdeungpo',
      ja: 'ソウル / 永登浦区',
      'zh-Hans': '首尔 / 永登浦',
      es: 'Seoul / Yeongdeungpo',
    },
    price: '₩130,000 ~',
  },
  {
    id: 10757,
    name: {
      ko: '워킹온더클라우드',
      en: 'WALKING ON THE CLOUD',
      ja: 'WALKING ON THE CLOUD',
      'zh-Hans': 'WALKING ON THE CLOUD',
      es: 'WALKING ON THE CLOUD',
    },
    desc: {
      ko: '서울이 한눈에 들어오는 로맨틱 다이닝',
      en: 'Romantic Dining with city view of Seoul',
      ja: 'ソウルが見渡せるロマンチックなダイニング',
      'zh-Hans': '将首尔尽收眼底的浪漫餐厅',
      es: 'Romantic Dining with city view of Seoul',
    },
    image: '/data/store/store/219066_7EFge3RFsFFKtCC6JG5vd50GQJRuVa9l.jpg',
    region: {
      ko: '서울 / 영등포',
      en: 'Seoul / Yeongdeungpo',
      ja: 'ソウル / 永登浦区',
      'zh-Hans': '首尔 / 永登浦',
      es: 'Seoul / Yeongdeungpo',
    },
    price: '₩85,000 ~',
  },
  {
    id: 10755,
    name: {
      ko: '슈치쿠',
      en: 'SHUCHIKU',
      ja: 'SHUCHIKU',
      'zh-Hans': 'SHUCHIKU',
      es: 'SHUCHIKU',
    },
    desc: {
      ko: '뷰맛집 하이엔드 오마카세',
      en: 'Highend Omakase with stunning view',
      ja: '美しいシティビューのハイエンドおまかせ',
      'zh-Hans': '顶级奢华景观、厨师发办餐厅',
      es: 'Highend Omakase with stunning view',
    },
    image: '/data/store/store/3363_fQuKZBXjpNVSOBamTTjUakVonVNjGEOh.jpg',
    region: {
      ko: '서울 / 영등포',
      en: 'Seoul / Yeongdeungpo',
      ja: 'ソウル / 永登浦区',
      'zh-Hans': '首尔 / 永登浦',
      es: 'Seoul / Yeongdeungpo',
    },
    price: '₩130,000 ~',
  },
  {
    id: 10758,
    name: {
      ko: '백리향',
      en: 'BAEK NI HYANG',
      ja: '百里香',
      'zh-Hans': '百里香',
      es: 'BAEK NI HYANG',
    },
    desc: {
      ko: '프리스티지 정통 중식 다이닝',
      en: 'Prestige Traditional Chinese Dining',
      ja: '高級感あふれる一流中華ダイニング',
      'zh-Hans': '纯正的顶级中餐厅',
      es: 'Prestige Traditional Chinese Dining',
    },
    image: '/data/store/store/3378_CwTcn08kmBJy3sSxva64QCZY03mlEO5Y.jpg',
    region: {
      ko: '서울 / 영등포',
      en: 'Seoul / Yeongdeungpo',
      ja: 'ソウル / 永登浦区',
      'zh-Hans': '首尔 / 永登浦',
      es: 'Seoul / Yeongdeungpo',
    },
    price: '₩210,000 ~',
  },
  {
    id: 10024,
    name: {
      ko: 'N그릴',
      en: 'n.GRILL',
      ja: 'n.GRILL',
      'zh-Hans': 'n.GRILL',
      es: 'n.GRILL',
    },
    desc: {
      ko: 'N서울타워 최고의 뷰',
      en: 'N Seoul Tower with glamorous view',
      ja: 'Nソウルタワー最高のビューを誇る',
      'zh-Hans': 'N首尔塔之绝景',
      es: 'N Seoul Tower with glamorous view',
    },
    image: '/data/store/store/76611_qOg4Nvfm7Dh0pRCHryxYCbMAzr7exbw0.png',
    region: {
      ko: '서울 / 용산',
      en: 'Seoul / Yongsan',
      ja: 'ソウル / 龍山区',
      'zh-Hans': '首尔 / 龙山',
      es: 'Seoul / Yongsan',
    },
    price: '₩420,000 ~',
  },
  {
    id: 10022,
    name: {
      ko: '크랩회피쉬',
      en: 'Crab Hoe Fish',
      ja: 'クラブ・フェ・フィッシュ',
      'zh-Hans': 'Crab Hoe Fish',
      es: 'Crab Hoe Fish',
    },
    desc: {
      ko: '대게 큰 킹크랩은',
      en: 'King crab in ',
      ja: 'ズワイガニとタラバガニ天国',
      'zh-Hans': '超大帝王蟹',
      es: 'King crab in ',
    },
    image: '/data/store/store/461333_AWQ1SknlHSNbRGpAjQE8ikVVFD5H6Hk5.jpg',
    region: {
      ko: '서울 / 영등포',
      en: 'Seoul / Yeongdeungpo',
      ja: 'ソウル / 永登浦区',
      'zh-Hans': '首尔 / 永登浦',
      es: 'Seoul / Yeongdeungpo',
    },
    price: '₩65,000 ~',
  },
  {
    id: 14207,
    name: {
      ko: '더플레이스다이닝',
      en: 'THE PLACE DINING',
      ja: 'THE PLACE Dining',
      'zh-Hans': 'THE PLACE Dining',
      es: 'THE PLACE DINING',
    },
    desc: {
      ko: 'N서울타워에서 감성 가득 낭만 가득',
      en: 'Enjoying Romantic dining from N Seoul Tower',
      ja: 'Nソウルタワーでおしゃれなダイニングを',
      'zh-Hans': '感性、浪漫的N首尔塔',
      es: 'Enjoying Romantic dining from N Seoul Tower',
    },
    image: '/data/store/store/store_14207_6969958cc9c9ebecccedfcd73970b890.jpg',
    region: {
      ko: '서울 / 용산',
      en: 'Seoul / Yongsan',
      ja: 'ソウル / 龍山区',
      'zh-Hans': '首尔 / 龙山',
      es: 'Seoul / Yongsan',
    },
    price: '₩252,000 ~',
  },
]

export const tour = [
  {
    must_eat_id: 8787,
    com_area_id: 180,
    name: {
      ko: '광양',
      ja: '光陽市',
      en: 'Gwangyang-si',
      'zh-Hans': '光阳',
      es: 'Gwangyang-si',
    },
    image: '/data/must_eat/8787_WG2xVhDSrP08VJKoS4hBy3znqs23a5qN.jpg',
  },
  {
    must_eat_id: 8784,
    com_area_id: 177,
    name: {
      ko: '여수',
      ja: '麗水市',
      en: 'Yeosu-si',
      'zh-Hans': '丽水',
      es: 'Yeosu-si',
    },
    image: '/data/must_eat/8784_EQB7ghVRCggA7RCV6sThM2gTTFNfsIjF.jpg',
  },
  {
    must_eat_id: 8785,
    com_area_id: 178,
    name: {
      ko: '순천',
      ja: '順天市',
      en: 'Suncheon-si',
      'zh-Hans': '顺天',
      es: 'Suncheon-si',
    },
    image: '/data/must_eat/8785_9emC3t2uHgMXp8ArbBsGJUH6atiWmQ8P.jpg',
  },
  {
    must_eat_id: 8783,
    com_area_id: 176,
    name: {
      ko: '목포',
      ja: '木浦市',
      en: 'Mokpo-si',
      'zh-Hans': '木浦',
      es: 'Mokpo-si',
    },
    image: '/data/must_eat/8783_2OPtccP4yGk7JUGZoFOqaifoHqcHgH6m.jpg',
  },
]

export const instance = [
  {
    id: '150833',
    name: {
      ko: '르브와',
      en: 'LEBOIS',
      ja: 'LEBOIS',
      'zh-Hans': 'LEBOIS',
      es: 'LEBOIS',
    },
    menu: {
      ko: '르브와 빠네',
      en: 'Le Bois Pasta in a Bread Bowl',
      ja: 'LeBoisパネ',
      'zh-Hans': 'Le Bois面包盅义大利面',
      es: 'Le Bois Pasta in a Bread Bowl',
    },
    image: '/data/product/71484_1DylvDSe9XdlStYEIwdNocWVX65RuQYl.jpg',
    region: {
      ko: '서울 / 종로구',
      en: 'Seoul / Jongno-gu',
      ja: 'ソウル / 鍾路区',
      'zh-Hans': '首尔 / 钟路区',
      es: 'Seoul / Jongno-gu',
    },
    price: '17,600',
  },
  {
    id: 10016,
    name: {
      ko: '명동한우방',
      en: 'Myeongdong Hanu Bang',
      ja: '明洞焼肉専門店（韓牛房）',
      'zh-Hans': '明洞韩牛房',
      es: 'Myeongdong Hanu Bang',
    },
    menu: {
      ko: '한우 소고기모듬(420g)',
      en: 'Assorted Korean Beef(420g)',
      ja: '韓牛の盛り合わせ(420g)',
      'zh-Hans': '韩牛肉拼盘(420g)',
      es: 'Assorted Korean Beef(420g)',
    },
    image: '/data/product/443_ZUp8SPXWq30x1trdWgCqd8NbQL7ZqbSe.jpg',
    region: {
      ko: '서울 / 중구',
      en: 'Seoul / Jung-gu',
      ja: 'ソウル / 中区',
      'zh-Hans': '首尔 / 中区',
      es: 'Seoul / Jung-gu',
    },
    price: '95,000',
  },
  {
    id: 10034,
    name: {
      ko: '853',
      en: '853',
      ja: '853',
      'zh-Hans': '853',
      es: '853',
    },
    menu: {
      ko: '목살(150g)',
      en: 'Pork Blade Shoulder(150g)',
      ja: '豚の肩肉(150g)',
      'zh-Hans': '猪肩肉(150g)',
      es: 'Pork Blade Shoulder(150g)',
    },
    image: '/data/product/23945_vVwVweGyzQtlNvLHVPwIzwEqbihHQHU6.png',
    region: {
      ko: '서울 / 종로',
      en: 'Seoul / Jongno-gu',
      ja: 'ソウル / 鐘路区',
      'zh-Hans': '首尔 / 钟路区',
      es: 'Seoul / Jongno-gu',
    },
    price: '16,000',
  },
  {
    id: 10045,
    name: {
      ko: '양연화로',
      en: 'Yangyeonhwaro',
      ja: 'ヤンヨンファロ',
      'zh-Hans': 'Yangyeonhwaro',
      es: 'Yangyeonhwaro',
    },
    menu: {
      ko: '양연모듬B SET(580g)',
      en: 'Yangyeon Assorted B SET(580g)',
      ja: 'ヤンヨン盛り合わせ B SET(580g)',
      'zh-Hans': '良缘拼盘B(580g)',
      es: 'Yangyeon Assorted B SET(580g)',
    },
    image: '/data/product/315_dZXKRmIT7AHbj93Hf2rswOZ32tyxI9SC.jpg',
    region: {
      ko: '서울 / 종로',
      en: 'Seoul / Jongno-gu',
      ja: 'ソウル / 鐘路区',
      'zh-Hans': '首尔 / 钟路区',
      es: 'Seoul / Jongno-gu',
    },
    price: '59,000',
  },
  {
    id: 10050,
    name: {
      ko: '오븐에빠진닭',
      en: 'OPPADAK',
      ja: 'オパダック',
      'zh-Hans': 'OPPADAK',
      es: 'OPPADAK',
    },
    menu: {
      ko: '오리지널 로스트',
      en: 'Original Roast',
      ja: 'オリジナルロースト',
      'zh-Hans': '原味烤鸡（整只）',
      es: 'Original Roast',
    },
    image: '/data/store/store/211_YCY2X5Dr6M5JenJL3cedFQFldNjNUnZO.jpg',
    region: {
      ko: '서울 / 중구',
      en: 'Seoul / Jung-gu',
      ja: 'ソウル / 中区',
      'zh-Hans': '首尔 / 中区',
      es: 'Seoul / Jung-gu',
    },
    price: '18,000',
  },
  {
    id: 10081,
    name: {
      ko: '만족오향족발',
      en: 'MAN JOK OH HYANG JOKBAL',
      ja: 'マンジョク・オヒャン・ジョクパル',
      'zh-Hans': '满足五香猪蹄',
      es: 'MAN JOK OH HYANG JOKBAL',
    },
    menu: {
      ko: '만족오향족발(특대)',
      en: "Manjok Five-spice Braised Pigs' Feet(Extra Jumbo Size)",
      ja: 'マンジョク五香豚足(特大)',
      'zh-Hans': '满足五香酱猪蹄（特大）',
      es: "Manjok Five-spice Braised Pigs' Feet(Extra Jumbo Size)",
    },
    image: '/data/product/77_yN6vqrxXQzbAhfkHOeV8o5d1xjOPrpdV.jpg',
    region: {
      ko: '서울 / 중구',
      en: 'Seoul / Jung-gu',
      ja: 'ソウル / 中区',
      'zh-Hans': '首尔 / 中区',
      es: 'Seoul / Jung-gu',
    },
    price: '57,000',
  },
  {
    id: 10090,
    name: {
      ko: '지미스',
      en: 'Jimmys',
      ja: 'Jimmys',
      'zh-Hans': 'Jimmys',
      es: 'Jimmys',
    },
    menu: {
      ko: '땅콩아이스크림',
      en: 'Peanuts Ice Cream',
      ja: 'ピーナッツアイスクリーム',
      'zh-Hans': '花生冰淇淋',
      es: 'Peanuts Ice Cream',
    },
    image: '/data/product/151_w3X7yy1w2mKFyvZ8BjUeelq5wQ4T2748.jpg',
    region: {
      ko: '제주 / 제주시',
      en: 'Jeju / Jeju-si',
      ja: '済州 / 済州市',
      'zh-Hans': '济州岛 / 済州',
      es: 'Jeju / Jeju-si',
    },
    price: '5,000',
  },
  {
    id: 10506,
    name: {
      ko: '최대감벌교꼬막',
      en: 'Choedaegam Beolgyo Kkomak',
      ja: 'チェデガム・ボルギョ・コマク',
      'zh-Hans': 'Choedaegam Beolgyo Kkomak',
      es: 'Choedaegam Beolgyo Kkomak',
    },
    menu: {
      ko: '꼬막정식(1인)',
      en: 'Cockle Set Menu(For 1)',
      ja: 'ハイガイ定食(1人)',
      'zh-Hans': '泥蚶套餐/1人份',
      es: 'Cockle Set Menu(For 1)',
    },
    image: '/data/store/store/857941_155912381.jpg',
    region: {
      ko: '전남 / 순천',
      en: 'Jeollanam-do / Suncheon-si',
      ja: '全羅南道 / 順天市',
      'zh-Hans': '全罗南道 / 顺天',
      es: 'Jeollanam-do / Suncheon-si',
    },
    price: '18,000',
  },
]

export const street = [
  {
    id: 10753,
    name: {
      ko: '바다풍경',
      en: 'Badapunggyeong',
      ja: 'パダプンギョン',
      'zh-Hans': 'Badapunggyeong',
      es: 'Badapunggyeong',
    },
    desc: {
      ko: '싱싱한 제주바다의 참맛',
      en: 'Fresh seafood of Jeju Island',
      ja: '済州島の新鮮な海の幸',
      'zh-Hans': '为您捧上济州新鲜的海产',
      es: 'Fresh seafood of Jeju Island',
    },
    image: '/data/store/store/62055_WuEoXKSTNm9P2Gqc9enpxNO95q6pk5Ry.png',
    region: {
      ko: '제주 / 제주시',
      en: 'Jeju / Jeju-si',
      ja: '済州 / 済州市',
      'zh-Hans': '济州岛 / 済州',
      es: 'Jeju / Jeju-si',
    },
    price: '45,000 ~',
    award: {
      ko: '안심식당',
      en: 'Safe Restaurant',
      ja: '安心食堂',
      'zh-Hans': '放心餐厅',
      es: 'Safe Restaurant',
    },
  },
  {
    id: 11432,
    name: {
      ko: '일미삼겹살',
      en: 'Ilmi Samgyeopsal',
      ja: 'イルミ・サムギョプサル',
      'zh-Hans': 'Ilmi Samgyeopsal',
      es: 'Ilmi Samgyeopsal',
    },
    desc: {
      ko: '미나리와 삼겹살, 찰떡궁합',
      en: 'Water Parsley and Pork Belly, a perfect match',
      ja: 'セリとサムギョプサルの香ばしい調和',
      'zh-Hans': '水芹与五花肉，天生一对',
      es: 'Water Parsley and Pork Belly, a perfect match',
    },
    image: '/data/store/store/5845_BpFsXYHj2EszHxwi48UsYlio9ClndsCD.png',
    region: {
      ko: '강원 / 원주',
      en: 'Gangwon-do / Wonju-si',
      ja: '江原道 / 原州市',
      'zh-Hans': '江原道 / 原州',
      es: 'Gangwon-do / Wonju-si',
    },
    price: '15,900 ~',
    award: {
      ko: '모범음식점',
      en: 'Exemplary Restaurant',
      ja: '模範飲食店',
      'zh-Hans': '模范餐厅',
      es: 'Exemplary Restaurant',
    },
  },
  {
    id: 25520,
    name: {
      ko: '카페해운대1994',
      en: 'Cafe Haeundae1994',
      ja: 'Cafe Haeundae1994',
      'zh-Hans': 'Cafe Haeundae1994',
      es: 'Cafe Haeundae1994',
    },
    desc: {
      ko: '해리단길에서 달콤한 디저트 타임',
      en: 'Enjoying desserts from Haeridan-gil',
      ja: '今人気のヘリダンキルで甘いデザートを',
      'zh-Hans': '在海里团路享受甜品盛宴',
      es: 'Enjoying desserts from Haeridan-gil',
    },
    image: '/data/store/store/515184_1490545627.jpg',
    region: {
      ko: '부산 / 해운대',
      en: 'Busan / Haeundae-gu',
      ja: '釜山 / 海雲台区',
      'zh-Hans': '釜山 / 海云台',
      es: 'Busan / Haeundae-gu',
    },
    price: '3,500 ~',
    award: {
      ko: '안심식당',
      en: 'Safe Restaurant',
      ja: '安心食堂',
      'zh-Hans': '放心餐厅',
      es: 'Safe Restaurant',
    },
  },
  {
    id: 11519,
    name: {
      ko: '명촌순두부보쌈',
      en: 'Myeongchon Sundubu Bossam',
      ja: 'ミョンチョンスンドゥブポサム',
      'zh-Hans': 'Myeongchon Sundubu Bossam',
      es: 'Myeongchon Sundubu Bossam',
    },
    desc: {
      ko: '순두부와 보쌈은 꿀조합',
      en: 'Irresistible combo of Soft Bean Curd and Pork',
      ja: 'スンドゥブとポサム、似合いますよ',
      'zh-Hans': '人间绝配—嫩豆腐与菜包肉',
      es: 'Irresistible combo of Soft Bean Curd and Pork',
    },
    image: '/data/store/store/461316_fOfjZb6s0IFm8T6UBsMcZNGjskvdH3Dt.jpg',
    region: {
      ko: '울산 / 북구',
      en: 'Ulsan / Buk-gu',
      ja: '蔚山 / 北区',
      'zh-Hans': '蔚山 / 北区',
      es: 'Ulsan / Buk-gu',
    },
    price: '11,000 ~',
    award: {
      ko: '안심식당',
      en: 'Safe Restaurant',
      ja: '安心食堂',
      'zh-Hans': '放心餐厅',
      es: 'Safe Restaurant',
    },
  },
  {
    id: 10772,
    name: {
      ko: '모아횟집',
      en: 'Moa Hoetjip',
      ja: 'モアフェッチプ',
      'zh-Hans': 'Moa Hoetjip',
      es: 'Moa Hoetjip',
    },
    desc: {
      ko: '대천 해수욕장을 한눈에',
      en: 'Daecheon Beach view at once',
      ja: 'テチョン海水浴場を一望しながら食べるお刺身',
      'zh-Hans': '可将大川海水浴场尽收眼底',
      es: 'Daecheon Beach view at once',
    },
    image: '/data/store/store/461320_IZzq9fgCSZkKnsdLeReXOxKCmcHb1kGH.jpg',
    region: {
      ko: '충남 / 보령',
      en: 'Chungcheongnam-do / Boryeong-si',
      ja: '忠清南道 / 保寧市',
      'zh-Hans': '忠清南道 / 保宁',
      es: 'Chungcheongnam-do / Boryeong-si',
    },
    price: '200,000 ~',
    award: {
      ko: '씨트립',
      en: 'Ctrip',
      ja: 'シートリップ',
      'zh-Hans': '携程',
      es: 'Ctrip',
    },
  },
  {
    id: 10977,
    name: {
      ko: '산속의친구',
      en: 'Sansogui Chingu',
      ja: 'サンソゲ・チングドゥル',
      'zh-Hans': 'Sansogui Chingu',
      es: 'Sansogui Chingu',
    },
    desc: {
      ko: '영월군 유일한 농가 맛집',
      en: 'The one and only farmhouse cuisine in Yeongwol',
      ja: '寧越郡にある唯一の農家食堂',
      'zh-Hans': '宁越郡唯一农家美食餐厅',
      es: 'The one and only farmhouse cuisine in Yeongwol',
    },
    image: '/data/product/2602_7lyHkNuNCoYzV0fi7hc0scCthQWHvPFu.jpg',
    region: {
      ko: '강원 / 영월',
      en: 'Gangwon-do / Yeongwol-gun',
      ja: '江原道 / 寧越郡',
      'zh-Hans': '江原道 / 宁越',
      es: 'Gangwon-do / Yeongwol-gun',
    },
    price: '20,000 ~',
    award: {
      ko: '안심식당',
      en: 'Safe Restaurant',
      ja: '安心食堂',
      'zh-Hans': '放心餐厅',
      es: 'Safe Restaurant',
    },
  },
  {
    id: 11437,
    name: {
      ko: '콩앤콩리',
      en: 'Kong & Kongni',
      ja: 'コン&コンニ',
      'zh-Hans': 'Kong & Kongni',
      es: 'Kong & Kongni',
    },
    desc: {
      ko: '영양만점 수제 전두부',
      en: 'Nutritious homemade Whole Bean Curd',
      ja: '栄養満点！手作り豆腐料理',
      'zh-Hans': '营养百分百手工全豆腐',
      es: 'Nutritious homemade Whole Bean Curd',
    },
    image: '/data/store/store/461327_YMXwPQ07GRwNBR6shWpsPBswZ0NQpipJ.jpg',
    region: {
      ko: '울산 / 남구',
      en: 'Ulsan / Nam-gu',
      ja: '蔚山 / 南区',
      'zh-Hans': '蔚山 / 南区',
      es: 'Ulsan / Nam-gu',
    },
    price: '10,000 ~',
    award: {
      ko: '모범음식점',
      en: 'Exemplary Restaurant',
      ja: '模範飲食店',
      'zh-Hans': '模范餐厅',
      es: 'Exemplary Restaurant',
    },
  },
  {
    id: 11197,
    name: {
      ko: '다복',
      en: 'Dabok',
      ja: 'タボク',
      'zh-Hans': 'Dabok',
      es: 'Dabok',
    },
    desc: {
      ko: '다양한 코스의 복요리를 즐길 수 있는 현지 찐맛집',
      en: 'Local hot place to enjoy the Puffer Fish in course',
      ja: '色とりどりのふぐコース料理',
      'zh-Hans': '百变河豚料理、当地美食店铺',
      es: 'Local hot place to enjoy the Puffer Fish in course',
    },
    image: '/data/store/store/5802_4M9vI6345ihcmUUhcMWpon9W4DMnGqjW.png',
    region: {
      ko: '전남 / 목포',
      en: 'Jeollanam-do / Mokpo-si',
      ja: '全羅南道 / 木浦市',
      'zh-Hans': '全罗南道 / 木浦',
      es: 'Jeollanam-do / Mokpo-si',
    },
    price: '100,000 ~',
    award: {
      ko: '',
      en: '',
      ja: '',
      'zh-Hans': '',
      es: '',
    },
  },
]

export const best = [
  {
    id: 10076,
    name: {
      ko: '금성관나주곰탕',
      en: 'Geumseonggwan Naju Gomtang',
      ja: 'クムソングァン ナジュコムタン',
      'zh-Hans': 'Geumseonggwan Naju Gomtang',
      es: 'Geumseonggwan Naju Gomtang',
    },
    desc: {
      ko: '40년 전통 국물이 일품인 나주곰탕',
      en: 'Naju Beef Bone Soup with 40years of tradition',
      ja: '40年の伝統を誇るナジュコムタンのお店',
      'zh-Hans': '40年如一、骨汤无与伦比！',
      es: 'Naju Beef Bone Soup with 40years of tradition',
    },
    image: '/data/product/62569_f1FalurQFKKqwbMa5SWrKxGeBZmVofce.jpg',
    region: {
      ko: '서울 / 중구',
      en: 'Seoul / Jung-gu',
      ja: 'ソウル / 中区',
      'zh-Hans': '首尔 / 中区',
      es: 'Seoul / Jung-gu',
    },
    price: '10,000 ~',
  },
  {
    id: 10092,
    name: {
      ko: '곰국시집',
      en: 'Gomguksijip',
      ja: 'コムグクシジプ',
      'zh-Hans': 'Gomguksijip',
      es: 'Gomguksijip',
    },
    desc: {
      ko: '명동의 터줏대감 잠실상륙!',
      en: "Myeongdong's oldest restaurant finally in Jamsil!",
      ja: '明洞の老舗グルメを蚕室でも！',
      'zh-Hans': '明洞老字号、攻略蚕室！',
      es: "Myeongdong's oldest restaurant finally in Jamsil!",
    },
    image: '/data/product/1780_kBQKTxBTQCjMEb6BX8CeDmh7hPN3A857.jpg',
    region: {
      ko: '서울 / 송파',
      en: 'Seoul / Songpa-gu',
      ja: 'ソウル / 松坡区',
      'zh-Hans': '首尔 / 松坡区',
      es: 'Seoul / Songpa-gu',
    },
    price: '10,500 ~',
  },
  {
    id: 10083,
    name: {
      ko: '유아히어카페',
      en: 'YOU ARE HERE CAFE',
      ja: 'YOU ARE HERE CAFE',
      'zh-Hans': 'YOU ARE HERE CAFE',
      es: 'YOU ARE HERE CAFE',
    },
    desc: {
      ko: '풍부한 맛의 수제 디저트가 땡기는 날',
      en: 'Craving for Homemade Desserts?',
      ja: '豊かな味の丁寧なデザートならここ。',
      'zh-Hans': '决不能错过！口感丰富的手工甜品',
      es: 'Craving for Homemade Desserts?',
    },
    image: '/data/product/25304_d7Lo3Sa2tkTZNxNMBNRXzNgdNPSLcNNm.jpg',
    region: {
      ko: '서울 / 중구',
      en: 'Seoul / Jung-gu',
      ja: 'ソウル / 中区',
      'zh-Hans': '首尔 / 中区',
      es: 'Seoul / Jung-gu',
    },
    price: '39,000 ~',
  },
  {
    id: 25416,
    name: {
      ko: '부산횟집',
      en: 'Busan Hoetjip',
      ja: 'プサン・フェッチプ',
      'zh-Hans': 'Busan Hoetjip',
      es: 'Busan Hoetjip',
    },
    desc: {
      ko: '자갈치시장에서 먹는 싱싱한 제철회',
      en: 'Fresh Seasonal Sashimi from Jagalchi Market',
      ja: 'チャガルチ市場で食べる新鮮な旬のお魚',
      'zh-Hans': '在扎嘎其市场吃新鲜的生鱼片',
      es: 'Fresh Seasonal Sashimi from Jagalchi Market',
    },
    image: '/data/store/store/461337_7ZmZfgR3EY0K9tPm56ZSZoVVkKhBUN47.jpg',
    region: {
      ko: '부산 / 중구',
      en: 'Busan / Jung-gu',
      ja: '釜山 / 中区',
      'zh-Hans': '釜山 / 中区',
      es: 'Busan / Jung-gu',
    },
    price: '30,000 ~',
  },
  {
    id: 10058,
    name: {
      ko: '꾸이점빵',
      en: 'Kkuijeomppang',
      ja: 'クイジョンパン',
      'zh-Hans': 'Kkuijeomppang',
      es: 'Kkuijeomppang',
    },
    desc: {
      ko: '선도 좋은 고기를 제공하는 일본식 야끼니꾸 전문점',
      en: 'Japanese grilled meat place with superb quality',
      ja: '新鮮なお肉が堪能できる和風焼肉専門店',
      'zh-Hans': '提供鲜肉的日式烧烤专卖店',
      es: 'Japanese grilled meat place with superb quality',
    },
    image: '/data/store/store/461339_nMQtFjQWrgoQ4BAmiF074trt6OuuE3wq.jpg',
    region: {
      ko: '부산 / 부산진구',
      en: 'Busan / Busanjin-gu',
      ja: '釜山 / 釜山鎮区',
      'zh-Hans': '釜山 / 釜山镇区',
      es: 'Busan / Busanjin-gu',
    },
    price: '6,900 ~',
  },
  {
    id: 10763,
    name: {
      ko: '정승골정육식당',
      en: 'Jeongseunggol Jeongyuk Sikdang',
      ja: 'チョンスンゴル・ジョンユクシクタン',
      'zh-Hans': 'Jeongseunggol Jeongyuk Sikdang',
      es: 'Jeongseunggol Jeongyuk Sikdang',
    },
    desc: {
      ko: '2대째 운영하는 농장에서 제공하는 신선한 육질의 향연',
      en: 'Fresh meat from the farm run for two generations ',
      ja: '二代目が運営中の農家から直接取り寄せる新鮮なお肉',
      'zh-Hans': '经两代人的传承、鲜肉盛宴',
      es: 'Fresh meat from the farm run for two generations ',
    },
    image: '/data/store/store/231353_l1UNWcmIQHXbrInbXICjVtohyfYykc0p.jpg',
    region: {
      ko: '강원 / 원주',
      en: 'Gangwon-do / Wonju-si',
      ja: '江原道 / 原州市',
      'zh-Hans': '江原道 / 原州',
      es: 'Gangwon-do / Wonju-si',
    },
  },
  {
    id: 10052,
    name: {
      ko: '연동 마라도횟집',
      en: 'Yeondong Marado Hoetjip',
      ja: 'ヨンドン マラドフェッチプ',
      'zh-Hans': 'Yeondong Marado Hoetjip',
      es: 'Yeondong Marado Hoetjip',
    },
    desc: {
      ko: '당일 잡은 신선한 해산물과 함께하는 생선회 천국!',
      en: 'Fresh Sea food and Sashimi of the day!',
      ja: '当日水揚げした海産物やお刺身が堪能できる',
      'zh-Hans': '当天捕获的新鲜海产及生鱼片',
      es: 'Fresh Sea food and Sashimi of the day!',
    },
    image: '/data/store/store/61609_Z2Nzi3kelzdHuLqLXBrFue5QOOBNioes.png',
    region: {
      ko: '제주 / 제주시',
      en: 'Jeju / Jeju-si',
      ja: '済州 / 済州市',
      'zh-Hans': '济州岛 / 済州',
      es: 'Jeju / Jeju-si',
    },
    price: '50,000 ~',
  },
  {
    id: 11183,
    name: {
      ko: '모란한정식',
      en: "Moran Korean Table d'hote",
      ja: 'モラン・ハンジョンシク',
      'zh-Hans': "Moran Korean Table d'hote",
      es: "Moran Korean Table d'hote",
    },
    desc: {
      ko: '푸짐함에 한번 맛에 두번 놀라는 강진 한정식 맛집',
      en: "Gangjin's hearty and savory Korean Table D'hote",
      ja: '美味しいおかずが盛沢山、康津の韓定食',
      'zh-Hans': '口感无与伦比的康津韩定食餐厅',
      es: "Gangjin's hearty and savory Korean Table D'hote",
    },
    image: '/data/product/2402_3yaq4lm3ldEAGih70RUqSFuJhuV2Xqal.jpg',
    region: {
      ko: '전남 / 강진',
      en: 'Jeollanam-do / Gangjin-gun',
      ja: '全羅南道 / 康津郡',
      'zh-Hans': '全罗南道 / 康津',
      es: 'Jeollanam-do / Gangjin-gun',
    },
    price: '50,000 ~',
  },
  {
    id: 29268,
    name: {
      ko: '해초연',
      en: 'Haechoyeon',
      ja: 'ヘチョヨン',
      'zh-Hans': 'Haechoyeon',
      es: 'Haechoyeon',
    },
    desc: {
      ko: '건강을 요리하는 사람들',
      en: 'Meals for Healthy Eaters',
      ja: '健康にいい料理が食べられるお店。',
      'zh-Hans': '健康美食尽在于此！',
      es: 'Meals for Healthy Eaters',
    },
    image: '/data/store/store/229724_WrjoxjJLXPoqaw6Z4yfZ7OHXHu8M4ZF6.png',
    region: {
      ko: '서울 / 영등포',
      en: 'Seoul / Yeongdeungpo',
      ja: 'ソウル / 永登浦区',
      'zh-Hans': '首尔 / 永登浦',
      es: 'Seoul / Yeongdeungpo',
    },
    price: '14,000 ~',
  },
]

export const healing = [
  {
    id: 434629,
    name: {
      ko: '언덕집',
      en: 'Eondeokjip',
      ja: 'オンドクチプ',
      'zh-Hans': 'Eondeokjip',
      es: 'Eondeokjip',
    },
    desc: {
      ko: '정성이 가득 담긴 양산 6대 추어탕',
      en: 'Top 6 Loach Soup in Yangsan',
      ja: '地元グルメトップ6のどじょうスープ',
      'zh-Hans': '梁山六大泥鳅汤美食店铺',
      es: 'Top 6 Loach Soup in Yangsan',
    },
    image: '/data/store/store/537120_C1wREta12D7JB2XHVplNYunthBSNLIP2.jpg',
    region: {
      ko: '경남 / 양산',
      en: 'Gyeongsangnam-do / Yangsan-si',
      ja: '慶尚南道 / 梁山市',
      'zh-Hans': '庆尚南道 / 梁山',
      es: 'Gyeongsangnam-do / Yangsan-si',
    },
    price: '10,000 ~',
  },
  {
    id: 35505,
    name: {
      ko: '세광양대창',
      en: 'Segwangyang Daechang',
      ja: 'セグァン ヤンデチャン',
      'zh-Hans': 'Segwangyang Daechang',
      es: 'Segwangyang Daechang',
    },
    desc: {
      ko: '세월의 추억이 피어오르는 양대창 맛집',
      en: 'Must-go Beef tripe and Intestines places',
      ja: '懐かしい雰囲気ホルモン焼き屋',
      'zh-Hans': '让人追忆的烤肠美食店铺',
      es: 'Must-go Beef tripe and Intestines places',
    },
    image: '/data/store/store/230808_sNcezRpOVJQ4PMlnWentHqH0w2pCL2sm.jpg',
    region: {
      ko: '서울 / 강남',
      en: 'Seoul / Gangnam',
      ja: 'ソウル / 江南区',
      'zh-Hans': '首尔 / 江南',
      es: 'Seoul / Gangnam',
    },
    price: '14,000 ~',
  },
  {
    id: 105890,
    name: {
      ko: '이선생 미국가주우육면',
      en: 'Iseonsaeng Miguk Gaju Uyungmyeon',
      ja: '李先生美国加州牛肉面大王',
      'zh-Hans': '李先生美国加州牛肉面大王',
      es: 'Iseonsaeng Miguk Gaju Uyungmyeon',
    },
    desc: {
      ko: '중국 본토 맛을 느낄 수 있는 우육면 대왕',
      en: 'Beef Noodle with authentic Chinese flavor',
      ja: '中国本場の料理が食べられる牛肉麺のお店',
      'zh-Hans': '中国风味—李先生牛肉面大王',
      es: 'Beef Noodle with authentic Chinese flavor',
    },
    image: '/data/store/store/103370_XaZ1O1PS3C217ZZIVMpiTWLUWtYJJDuJ.jpeg',
    region: {
      ko: '서울 / 영등포',
      en: 'Seoul / Yeongdeungpo',
      ja: 'ソウル / 永登浦区',
      'zh-Hans': '首尔 / 永登浦',
      es: 'Seoul / Yeongdeungpo',
    },
    price: '8,000 ~',
  },
  {
    id: 10750,
    name: {
      ko: '와룽빠뜨릭',
      en: 'Warung Patrick',
      ja: 'ワルンパトゥリク',
      'zh-Hans': 'Warung Patrick',
      es: 'Warung Patrick',
    },
    desc: {
      ko: '바다 앞 한국에서 만나는 발리의 맛',
      en: 'Enjoying the taste of Bali in Korea',
      ja: '韓国で楽しむバリ島の味',
      'zh-Hans': '在韩国海边遇见巴厘岛风味',
      es: 'Enjoying the taste of Bali in Korea',
    },
    image: '/data/store/store/62068_Xeab3E4hX5xCP4Nq39TfHBC0RWq88mxy.jpg',
    region: {
      ko: '강원 / 양양',
      en: 'Gangwon-do / Yangyang-gun',
      ja: '江原道 / 襄陽郡',
      'zh-Hans': '江原道 / 襄阳',
      es: 'Gangwon-do / Yangyang-gun',
    },
    price: '12,500 ~',
  },
  {
    id: 10487,
    name: {
      ko: '청춘 파스타',
      en: 'Cheongchun Pasta',
      ja: 'チョンチュン パスタ',
      'zh-Hans': 'Cheongchun Pasta',
      es: 'Cheongchun Pasta',
    },
    desc: {
      ko: '청춘들아 모여라! 정성 가득한 파스타',
      en: 'Come and enjoy the Heartful Pasta!',
      ja: '丁寧な味のパスタが食べたいならここ。',
      'zh-Hans': '来吧青春！心意满满的意大利面',
      es: 'Come and enjoy the Heartful Pasta!',
    },
    image: '/data/store/store/61733_zadjUaBs6EGwVRuL568UzmmSJlGZwugH.png',
    region: {
      ko: '전남 / 여수',
      en: 'Jeollanam-do / Yeosu-si',
      ja: '全羅南道 / 麗水市',
      'zh-Hans': '全罗南道 / 丽水',
      es: 'Jeollanam-do / Yeosu-si',
    },
    price: '9,000 ~',
  },
  {
    id: 10067,
    name: {
      ko: '띠띠빵빵',
      en: 'Ttittippangppang',
      ja: 'ティティパンパン',
      'zh-Hans': 'Ttittippangppang',
      es: 'Ttittippangppang',
    },
    desc: {
      ko: '우도의 수제버거와 해물짬뽕의 만남',
      en: "Udo's Handmade Burger and Spicy Seafood Noodle Soup",
      ja: '牛島名物手作りバーガーと海鮮チャンポン',
      'zh-Hans': '牛岛手工汉堡与海鲜炒码面的偶遇',
      es: "Udo's Handmade Burger and Spicy Seafood Noodle Soup",
    },
    image: '/data/store/store/18299_NJ5qyFnycOvTDE5OWzV7zmsQKOe4VxLl.jpg',
    region: {
      ko: '제주 / 제주시',
      en: 'Jeju / Jeju-si',
      ja: '済州 / 済州市',
      'zh-Hans': '济州岛 / 济州',
      es: 'Jeju / Jeju-si',
    },
    price: '10,000 ~',
  },
  {
    id: 10992,
    name: {
      ko: '동명카츠',
      en: 'Dongmyeong Kacheu',
      ja: '東明カチュ',
      'zh-Hans': 'Dongmyeong Kacheu',
      es: 'Dongmyeong Kacheu',
    },
    desc: {
      ko: '착한 가격으로 만나는 수제 돈카츠',
      en: 'Handmade Pork Cutlet at a good price',
      ja: 'コスパ最高の手作りトンカツ',
      'zh-Hans': '物超所值的手工炸猪排',
      es: 'Handmade Pork Cutlet at a good price',
    },
    image: '/data/product/2293_oFxh8HXgcH6awRGkTde3CAkE4R4UVnTD.jpg',
    region: {
      ko: '광주 / 광산',
      en: 'Gwangju / Gwangsan-gu',
      ja: '光州 / 光山区',
      'zh-Hans': '光州 / 光山',
      es: 'Gwangju / Gwangsan-gu',
    },
    price: '3,500 ~',
  },
  {
    id: 28958,
    name: {
      ko: '피슈마라홍탕',
      en: 'PIXIU MALA HONGTANG',
      ja: 'PIXIU MALA HONGTANG',
      'zh-Hans': '貔貅麻辣红汤',
      es: 'PIXIU MALA HONGTANG',
    },
    desc: {
      ko: '마라의 얼얼함에 중독되다',
      en: 'Mala Soup Addiction',
      ja: 'マーラーのおいしい辛さ、たまらない！',
      'zh-Hans': '麻辣的诱惑',
      es: 'Mala Soup Addiction',
    },
    image: '/data/product/62996_kuZ8cWmaJeV6BxDewKz4I5NQ9blmUfxX.jpg',
    region: {
      ko: '서울 / 영등포',
      en: 'Seoul / Yeongdeungpo',
      ja: 'ソウル / 永登浦区',
      'zh-Hans': '首尔 / 永登浦',
      es: 'Seoul / Yeongdeungpo',
    },
    price: '2,000 ~ / 100g',
  },
  {
    id: 35473,
    name: {
      ko: '퐁당퐁당',
      en: 'Pongdang Pongdang',
      ja: 'ポンダン・ポンダン',
      'zh-Hans': 'Pongdang Pongdang',
      es: 'Pongdang Pongdang',
    },
    desc: {
      ko: '요즘 핫한 빵들이 다 모인 베이커리',
      en: 'Bakery with recent Trendy Breads',
      ja: '今話題のパンここに全部集合！',
      'zh-Hans': '人气面包盛宴',
      es: 'Bakery with recent Trendy Breads',
    },
    image: '/data/product/19326_9LqcfYbrfNERSAhlqFufui2jIs97SiE7.jpg',
    region: {
      ko: '서울 / 영등포',
      en: 'Seoul / Yeongdeungpo',
      ja: 'ソウル / 永登浦区',
      'zh-Hans': '首尔 / 永登浦',
      es: 'Seoul / Yeongdeungpo',
    },
    price: '2,000 ~',
  },
  {
    id: 109397,
    name: {
      ko: '커피엔스',
      en: 'COFFIENS',
      ja: 'COFFIENS',
      'zh-Hans': 'COFFIENS',
      es: 'COFFIENS',
    },
    desc: {
      ko: '맛과 가성비 둘 다 잡은 커피 맛집',
      en: 'Best Coffee with Best Price',
      ja: '美味しくてコスパのいいカフェ',
      'zh-Hans': '性价比超高的咖啡店',
      es: 'Best Coffee with Best Price',
    },
    image: '/data/product/62994_BVKSUHmpoYSVbDeDFm0SK4nhuQYfoZZh.jpg',
    region: {
      ko: '서울 / 영등포',
      en: 'Seoul / Yeongdeungpo',
      ja: 'ソウル / 永登浦区',
      'zh-Hans': '首尔 / 永登浦',
      es: 'Seoul / Yeongdeungpo',
    },
    price: '1,000 ~',
  },
]
