import React from 'react'
import { useRouter } from 'next/router'

import styled from '@emotion/styled'

const Coupon = () => {
  const router = useRouter()
  const lang = router.locale

  const deals = {
    ko: '혜택',
    en: 'Deals',
    es: 'Deals',
    ja: '特典',
    'zh-Hans': '优惠',
    'zh-cn': '优惠',
  }

  const discount = {
    ko: '쿠폰할인',
    en: 'Discount',
    es: 'Discount',
    ja: '割引',
    'zh-Hans': '打折',
    'zh-CN': '打折',
  }

  return (
    lang && (
      <Container>
        <Red>{deals[lang]}</Red>
        <White>{discount[lang]}</White>
      </Container>
    )
  )
}

export default Coupon

const Container = styled.div`
  display: flex;
  margin: 8px 0 4px;
`

const Red = styled.div`
  font-family: 'Noto Sans KR';
  font-size: 12px;
  padding: 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #ff4a52;
  color: white;
`

const White = styled.div`
  font-family: 'Noto Sans KR';
  font-size: 12px;
  padding: 2px 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #ff4a52;
  color: #ff4a52;
`
