import React from 'react'

import styled from '@emotion/styled'
import ReactHtmlParser from 'react-html-parser'

const CompanyInfo = ({ t, channel = 'redtable' }) => {
  return (
    <Container>
      {channel === 'songjeongsurfvillage' && (
        <>
          {ReactHtmlParser(t('songjeong_info'))}
          <br />
          <br />
        </>
      )}
      {ReactHtmlParser(t('company_info'))}
    </Container>
  )
}

export default CompanyInfo

const Container = styled.address`
  width: 100%;
  background-color: #f3f3f3;
  padding: 32px;
  margin-top: 32px;

  font-style: normal;
  font-size: 12px;
  font-weight: 300;
  color: #888;
  line-height: 1.8;
`
