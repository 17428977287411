import React from 'react'
import Link from 'next/link'

import styled from '@emotion/styled'

import BuyNow from '../common/icon/BuyNow'

// id : 링크 연결용 id (필수)
// name (필수)
// desc (필수)
// region (필수)
// prioce (필수)
// contents (필수)

const FlexBanner = ({ id, name, desc, region, price, contents, t }) => {
  return (
    <Container>
      <Link rel="preload" as={`/food/${id}`} href={`/food/${id}`}>
        {contents}
        <InfoBox>
          <Region>{region}</Region>
          <Desc>{desc}</Desc>
          <Name>{name}</Name>
          <Price>{price}</Price>
        </InfoBox>
        <BuyButton>
          <BuyNow />
          <p>{t('product:pay_reserve')}</p>
        </BuyButton>
      </Link>
    </Container>
  )
}

export default FlexBanner

const Container = styled.div`
  height: 200px;
  position: relative;
  cursor: pointer;
  color: white;

  &::before {
    content: '';
    width: 100%;
    height: 200px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.3);
    position: absolute;
  }
`

const InfoBox = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px 0 0 24px;
`

const Region = styled.p`
  font-size: 12px;
  font-weight: 300;
`

const Desc = styled(Region)`
  padding-top: 8px;
`

const Name = styled.h4`
  font-size: 24px;
  font-weight: 500;
`

const Price = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  letter-spacing: 1px;
`

const BuyButton = styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;

  height: 30px;
  display: flex;
  align-items: center;
  background-color: #ff4a52;
  border-radius: 15px;
  padding: 0 12px 0 4px;

  p {
    font-size: 14px;
    font-weight: 300;
    line-height: 1;
    margin-bottom: 3px;
  }
`
