import React from 'react'
import Image from 'next/image'

const Reservation = () => {
  return (
    <Image
      src={'/icon/reservation_ico.png'}
      alt=""
      width={24}
      height={24}
      quality={100}
    />
  )
}

export default Reservation
