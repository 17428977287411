import React from 'react'
import Link from 'next/link'

import styled from '@emotion/styled'

import BuyNow from '../common/icon/BuyNow'
import Coupon from '../common/Coupon'

const StreetBanner = ({ id, region, desc, name, price, award, contents }) => {
  return (
    <Container>
      <Link href={`/food/${id}`}>
        {contents}
        <InfoBox>
          <li>
            <Region>{region}</Region>
          </li>
          <li>
            <Desc>{desc}</Desc>
          </li>
          <li>
            <Name>{name}</Name>
          </li>
          <li>
            <Price>
              <BuyNow />
              <h4>{price}</h4>
            </Price>
          </li>
          <Coupon />
          <Award received={award}>{award ? award : '　'}</Award>
        </InfoBox>
      </Link>
    </Container>
  )
}

export default StreetBanner

const Container = styled.div`
  /* background-color: #e6fffb; */
  border-radius: 5px;
  cursor: pointer;
`
const InfoBox = styled.ul`
  padding: 8px 0;
`

const Region = styled.p`
  font-size: 12px;
  font-weight: 100;
  color: #333;
  line-height: 1;
`

const Desc = styled.p`
  height: 24px;
  font-size: 12px;
  font-weight: 100;
  word-break: break-all;
  color: #333;
  margin-top: 6px;
  line-height: 1;
`

const Name = styled.h4`
  font-size: 14px;
  font-weight: 700;
  color: #333;
  margin-top: 4px;
`

const Price = styled.div`
  display: flex;
  margin-top: 4px;
  align-items: center;

  h4 {
    font-size: 16px;
    font-weight: 700;
    margin-left: 4px;
    padding-bottom: 2px;
  }
`

const Award = styled.div`
  font-family: 'Noto Sans KR';
  font-size: 12px;
  font-weight: 400;
  color: #10ad95;
  border: ${(props) => (props.received ? '1px solid #10ad95' : 'none')};
  width: fit-content;
  padding: 2px 4px;
  margin: 8px 0 4px;
  letter-spacing: -0.5px;
`
