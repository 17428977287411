import React from 'react'
import Image from 'next/image'

const BuyNow = ({ className }) => {
  return (
    <Image
      src={'/icon/buynow_ico.png'}
      alt=""
      width={24}
      height={24}
      quality={100}
      className={className}
    />
  )
}

export default BuyNow
