import React from 'react'
import Link from 'next/link'

import styled from '@emotion/styled'

// https://fonts.google.com/icons
// image : 구글 아이콘 클래스 네임 (필수)
// label : 하단 설명 텍스트
// link : 라우터 이동할 페이지 주소
// color : 클릭되었을 때 색상변경

const Icon = ({ image, label, link, color, length }) => {
  return (
    <Container color={color}>
      {link ? (
        <Link href={link}>
          <span className="material-icons-outlined">
            {length && (
              <Length>
                <p>{length}</p>
              </Length>
            )}
            {image}
          </span>
          {label && <Label>{label}</Label>}
        </Link>
      ) : (
        <>
          <span className="material-icons-outlined">
            {length && (
              <Length>
                <p>{length}</p>
              </Length>
            )}
            {image}
          </span>
          {label && <Label>{label}</Label>}
        </>
      )}
    </Container>
  )
}

export default Icon

const Container = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  fill: red;

  a {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  .material-icons-outlined {
    color: ${(props) => props.color};
    position: relative;
  }
`

const Label = styled.p`
  font-size: 10px;
  font-weight: 300;
  width: max-content;
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`

const Length = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: white;
    font-size: 12px;
    font-weight: 300;
  }
`
