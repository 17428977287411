import React, { useEffect, useState } from 'react'
import Image from 'next/image'

import styled from '@emotion/styled'

import { Cookies } from 'react-cookie'
import { HOST, TAIL } from 'constants/image'

const Popup = ({ data, t }) => {
  const cookies = new Cookies()

  // 쿠키 생성 후 닫기
  const closePopup = (id, expireDay) => {
    // 팝업 닫기
    document.querySelector(`#popup_${id}`).style.visibility = 'hidden'

    // 쿠키 생성
    cookies.set(`popup_${id}`, true, {
      path: '/',
      expires: new Date(new Date().setDate(new Date().getDate() + expireDay)),
    })

    // 모든 팝업이 닫히면 딤 닫기
    if (countOpenedPopup() === 0) displayNoneDim()
  }

  // 현재 팝업의 상태를 체크하여 배열로 생성해줌
  const checkPopupState = () => {
    const ele = document.querySelector('.popup_container')
    if (!ele) return []
    const count = ele.childElementCount

    // 팝업 순서대로 닫힌 상태면 true, 열린상태면 false 반환
    let checkingArr = []
    for (let i = 0; i < count; i++) {
      const visibility =
        ele.children[i].style.visibility === 'hidden' ||
        ele.children[i].style.display === 'none'
      checkingArr[i] = visibility
    }

    return checkingArr
  }

  // 현재 열려있는 팝업의 갯수 구하는 함수
  const countOpenedPopup = () => {
    return checkPopupState().filter((item) => item === false).length
  }

  // dim 영역 끄는 함수
  const displayNoneDim = () => {
    const ele = document.querySelector('.popup_section')
    if (!ele) return
    ele.style.display = 'none'
  }

  useEffect(() => {
    if (countOpenedPopup() === 0) displayNoneDim()
  }, [t('close')])

  const [imgIsLoaded, setImgIsLoaded] = useState(false)
  const onLoad = () => setImgIsLoaded(true)

  return (
    data.length > 0 && (
      <PopupSection className="popup_section">
        <PopupContainer
          className="popup_container"
          style={{ visibility: imgIsLoaded ? 'visible' : 'hidden' }}
        >
          {data.map((item, index) => (
            <PopupBody key={item.id} id={`popup_${item.id}`}>
              <Image
                src={`${HOST}${item.path}${TAIL}`}
                alt=""
                priority={index === 0}
                width={0}
                height={0}
                sizes="fill"
                style={{
                  width: '100%',
                  height: '100%',
                  cursor: item.link ? 'pointer' : 'inherit',
                }}
                onLoad={onLoad}
                onClick={() => window.open(item.link)}
              />
              <div style={{ display: 'flex', marginTop: -5 }}>
                <CloseButton onClick={() => closePopup(item.id, 3)}>
                  {t('close_3day')}
                </CloseButton>
                <CloseButton onClick={() => closePopup(item.id, 1)}>
                  {t('close')}
                </CloseButton>
              </div>
            </PopupBody>
          ))}
        </PopupContainer>
      </PopupSection>
    )
  )
}

export default Popup

const PopupSection = styled.section`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`

const PopupContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 480px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`

const PopupBody = styled.div`
  position: absolute;
  width: 100%;
  max-width: 480px;
`

const CloseButton = styled.button`
  width: 50%;
  height: 64px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`
