import React from 'react'
import Link from 'next/link'

import styled from '@emotion/styled'

import Icon from './Icon'

const Terms = ({ t }) => {
  const lists = [
    {
      name: 'FAQ',
      icon: 'help',
      link: '/faq',
    },
    {
      name: t('term'),
      icon: 'assignment',
      link: '/term',
    },
    {
      name: t('privacy'),
      icon: 'policy',
      link: '/privacy',
    },
    {
      name: t('refund'),
      icon: 'undo',
      link: '/refund',
    },
    {
      name: t('usage'),
      icon: 'info',
      link: '/usage',
    },
  ]

  return (
    <Container>
      <ul>
        {lists.map((item) => (
          <li key={item.icon}>
            <Link href={item.link}>
              <Icon image={item.icon} color={'#888'} />
              <label>{item.name}</label>
            </Link>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default Terms

const Container = styled.div`
  width: 100%;
  border: 6px solid #f3f3f3;
  margin: 32px 0 -32px;
  padding: 16px 28px;

  a {
    display: contents;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    width: fit-content;
    cursor: pointer;
  }

  label {
    display: block;
    font-size: 12px;
    color: #888;
    font-weight: 300;
    margin-left: 8px;
    margin-bottom: 2px;
    cursor: pointer;
  }
`
