/* eslint-disable @next/next/no-img-element */
import React from 'react'
import Link from 'next/link'

import styled from '@emotion/styled'
import BuyNow from '../common/icon/BuyNow'
import Bookmark from 'components/common/Bookmark'

const MusteatBanner = ({ data, contents, t }) => {
  const {
    store_id,
    translate_store_name,
    translate_branch_name,
    rti_star,
    show_cnt,
    translate_product_name,
    translate_label_name,
    price,
    currency,
    product_sale_status,
    store_contract_status,
    reserve_status,
  } = data

  return (
    <Container>
      <Link href={`/food/${store_id}`}>
        {contents}
        <Bookmark store_id={data.store_id} />
        <InfoBox>
          <li>
            <Name className="ellipsis">{translate_store_name}</Name>
            <Name className="ellipsis">
              {translate_branch_name ? ` (${translate_branch_name})` : '　'}
            </Name>
          </li>
          <li className="display-flex">
            <img src="/icon/star.svg" alt="" width={16} height={16} />
            <Star>
              {rti_star ?? '0.0'}{' '}
              {show_cnt ? `(${show_cnt.toLocaleString('ko-kr')})` : '(0)'}
            </Star>
          </li>
          <li>
            <Product className="clamp2">{translate_product_name}</Product>
          </li>
          <li>
            <Label className="clamp2">{translate_label_name}</Label>
          </li>
          <li className="display-flex">
            {price ? (
              <>
                <Price>{parseInt(price).toLocaleString('ko-kr')}</Price>
                <Currency>{currency}</Currency>
              </>
            ) : (
              <p style={{ height: 24 }}></p>
            )}
          </li>
          {(product_sale_status === 'sale' &&
            store_contract_status === 'normal') ||
          reserve_status === 'open' ? (
            <Button>
              <BuyNow />
              <span>{t('product:pay_reserve')}</span>
            </Button>
          ) : (
            <div style={{ height: 24 }}></div>
          )}
        </InfoBox>
      </Link>
    </Container>
  )
}

export default MusteatBanner

const Container = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  cursor: pointer;

  .favorite {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 5px;
    right: 0;
  }

  .favorite-children {
    position: absolute;
    top: 0;
    right: 5px;
  }

  .display-flex {
    display: flex;
    align-items: center;
  }

  li {
    margin-bottom: 8px;
  }

  .filter {
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.2));
  }
`

const InfoBox = styled.ul`
  padding: 8px 0 8px 8px;
`

const Name = styled.h4`
  font-size: 14px;
`

const Star = styled.p`
  color: #777;
  font-size: 12px;
  margin-left: 4px;
`

const Product = styled.h4`
  font-size: 14px;
  height: 44px;
`

const Label = styled.p`
  height: 34px;
  font-size: 12px;
  font-weight: 300;
  color: #888;
`

const Price = styled.h4`
  font-size: 16px;
`

const Currency = styled.span`
  font-size: 12px;
  font-weight: 300;
  color: #777;
  margin-left: 4px;
`

const Button = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
    color: #777;
  }
`
