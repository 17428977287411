import React from 'react'
import Link from 'next/link'

import styled from '@emotion/styled'

import BuyNow from '../common/icon/BuyNow'
import Coupon from '../common/Coupon'

const InstanceBanner = ({ id, region, name, menu, price, contents }) => {
  return (
    <Container>
      <Link href={`/food/${id}`}>
        {contents}
        <InfoBox>
          <li>
            <Region>{region}</Region>
          </li>
          <li>
            <Name>{name}</Name>
          </li>
          <li>
            <Menu>{menu}</Menu>
          </li>
          <li>
            <Price>
              <BuyNow />
              <h4>{price}</h4>
            </Price>
          </li>
          <Coupon />
        </InfoBox>
      </Link>
    </Container>
  )
}

export default InstanceBanner

const Container = styled.div`
  /* background-color: #e6fffb; */
  border-radius: 5px;
  cursor: pointer;
`
const InfoBox = styled.ul`
  padding: 8px 0;
`

const Region = styled.p`
  font-size: 12px;
  font-weight: 100;
  color: #333;
  line-height: 1;
`

const Name = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #888;
  margin-top: 2px;
`

const Menu = styled.h4`
  font-size: 14px;
  font-weight: 700;
  color: #333;
  margin-top: 4px;
`

const Price = styled.div`
  display: flex;
  margin-top: 4px;
  align-items: center;

  h4 {
    font-size: 16px;
    font-weight: 700;
    margin-left: 4px;
    padding-bottom: 2px;
  }
`
