import React from 'react'
import Link from 'next/link'

import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'
import { Lazy, Pagination, Navigation } from 'swiper'

import { useRouter } from 'next/router'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

// https://swiperjs.com/demos
// label (필수)
// contents : 실제 슬라이드로 들어갈 내용 - { SwiperSlide } import 해야함 (필수)

const Carousel = ({ label, description, contents, option, mx, more }) => {
  const router = useRouter()
  const lang = router.locale

  const moreText = {
    ko: '더보기',
    en: 'more',
    ja: 'もっと見る',
    ['zh-Hans' || 'zh-cn']: '更多',
    es: 'más',
  }

  const options = {
    lazy: true,
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    spaceBetween: 8,
    slidesOffsetBefore: 16,
    slidesOffsetAfter: 16,
    // modules: [Lazy, Autoplay, Pagination, Navigation],
    modules: [Lazy, Pagination, Navigation],
    ...option,
  }

  return (
    <>
      {label && (
        <Label>
          <h6>{label}</h6>
          {lang && more && <Link href={more}>{moreText[lang]}</Link>}
        </Label>
      )}
      {description && <Description>{description}</Description>}
      <Swiper {...options} lazy={true} style={{ margin: mx ? '0 16px' : null }}>
        {contents}
      </Swiper>
    </>
  )
}

export default Carousel

const Label = styled.label`
  width: 100%;
  margin: 16px 0 8px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;

  h6 {
    font-size: 16px;
    font-weight: 600;
    color: #121212;
  }

  a {
    font-size: 12px;
    font-weight: 300;
    color: #777;
    cursor: pointer;
  }
`

const Description = styled.p`
  padding: 0 16px;
  font-size: 14px;
  color: #888;
  margin-bottom: 16px;
`
